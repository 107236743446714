// ComingSoon.js
import React from "react";
import './ComingSoon.css';
import logo from "../../assets/img/logo.png"
const ComingSoon = () => {
  const companyName = "Couple Memory";
  return (
    <div className="coming-soon">
      {/* Şirket Logosu */}
      <div className="logo">
        <img src={logo} alt="Creative Software Solution Logo" />
      </div>

      <h1 className="company-name">
        {companyName.split("").map((char, index) => (
          <span
            key={index}
            className={`${index === 0 || companyName[index - 1] === " " ? "first-letter" : ""} ${char === " " ? "space" : ""}`}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            {char}
          </span>
        ))}
      </h1>
      <p>Çok Yakında Sizinle!</p>

      <div className="social-links">
        <a href="https://github.com/BurhanSaglanmak" className="button" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://www.linkedin.com/in/burhansaglanmak/" className="button" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
      <p>Bizimle iletişimde kalın: <a href="mailto:creativesoftsolutionhub@gmail.com">couplememoryofficial@gmail.com</a></p>
    </div>
  );
};

export default ComingSoon;
